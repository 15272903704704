import React, { createContext, useState, useEffect, useReducer } from "react";
import $ from "../../../../hooks/$"
import VARS from "../../../../hooks/VARS";
import { useTheme } from '@mui/material/styles';
import jwtDecode from 'jwt-decode';

import { useNavigate } from "react-router-dom";
import { PATH } from "../../../../pages/routes";

import Cache from "../../../../hooks/Cache";
import Cookies from 'js-cookie';

export const NooboxContent = createContext();

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {},
  permissions: {...PATH.public}
};

const handlers = {
  RELOAD: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    const role = user?.rol;
    const permissions = PATH[role?.code]??{};

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      permissions: {...PATH.public, ...permissions}
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    const role = user?.rol;

    const permissions = PATH[role?.code]??{};
    return {
      ...state,
      isAuthenticated: true,
      user,
      permissions: {...PATH.public, ...permissions}
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: {},
    permissions: {...PATH.public}
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;
    const role = user?.rol;
    const permissions = PATH[role?.code]??{}
    return {
      ...state,
      isAuthenticated: true,
      user,
      permissions: {...PATH.public, ...permissions}
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  } else {
    localStorage.removeItem('accessToken');
  }
};

const getCookiesLang = ()=>{
  const lang = Cookies.get('langUlaboat');
  
  if(lang === null || lang === false || lang === undefined || lang === ''){
    return VARS.lang;
  }

  return lang;
}

export const NooboxProvider = ({ children }) => {

  const JSON_PARSE = ($var) => {
    try {
      return JSON.parse($var);
    } catch (error) {
      return {}
    }
  }

  const isDesktop = VARS.useResponsive('up', 'lg');
  const isSmallMobile = VARS.useResponsive('down', 'sm');

  const [state, dispatch] = useReducer(reducer, initialState);

  const [lang, setLang] = useState(getCookiesLang());
  const [currency, setCurrency] = useState(VARS.divisaCurrent);
  const [user_field_value, setUserValue] = useState({});
  const [user_field, setUserField] = useState({});
  const [user, setUser] = useState(() => {
    const auth = localStorage.getItem(VARS.name + "-session");
    return auth ? JSON_PARSE(auth) : {};
  });
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem(VARS.name + "-messages");
    return savedMessages ? JSON_PARSE(savedMessages) : {};
  });
  const [forms, setForms] = useState(() => {
    const savedForms = localStorage.getItem(VARS.name + "-forms");
    return savedForms ? JSON_PARSE(savedForms) : {};
  });
  const [boatInput, setBoatInput] = useState(() => {
    const savedInput = localStorage.getItem(VARS.name + "-boatinput");
    return savedInput ? JSON_PARSE(savedInput) : {};
  });
  const [serviceInput, setServiceInput] = useState(() => {
    const savedInput = localStorage.getItem(VARS.name + "-serviceinput");
    return savedInput ? JSON_PARSE(savedInput) : {};
  });
  const [cart, setCart] = useState(() => {
    const crt = localStorage.getItem(VARS.name + "-cart");
    return crt ? JSON_PARSE(crt) : [];
  });
  const [loading, setLoading] = useState(false);
  const [typedboat, setTypedboat] = useState(null);
  const [cancellations, setCancellations] = useState(false);
  const [alert, setAlert] = useState(null);
  const [navbar, setNavbar] = useState(() => {
    const nb = localStorage.getItem(VARS.name + "-navbar");
    return nb ? JSON_PARSE(nb) : { open: false };
  });
  const [versioned, setVersioned] = useState(() => {
    const ver = localStorage.getItem(VARS.name + "-versioned");
    return ver ? JSON_PARSE(ver) : { ver: false };
  });
  const [listnavbar, setListNavbar] = useState(() => {
    const lnb = localStorage.getItem(VARS.name + "-listnavbar");
    return lnb ? JSON_PARSE(lnb) : [];
  });

  const theme = useTheme();
  // Obtenemos las funciones useNavigate y useParams del Router
  const navigate = useNavigate();

  //Typeboats
  const getField = (terms, fieldName) => {
    let field = {};
    for (const e in terms?.fields) {
      if (terms?.fields[e].name === fieldName) {
        field = terms?.fields[e];
        break;
      }
    }
    return field;
  };
  //Carrito

  const Cart = () => {
    const getAll = () => {
      return cart;
    };

    const exists = (item) => {
      let cart = getAll();
      let result = false;
      if(item?.identity){
        for (const key in cart) {
          if (parseInt(cart[key].identity) === parseInt(item.identity)) {
            result = true;
          }
        }        
      }
      return result;
    };

    const getItem = (identity) => {
      let cart = getAll();
      let result = null;
      for (const key in cart) {
        if (parseInt(cart[key].identity) === parseInt(identity)) {
          result = cart[key];
        }
      }
      return result;
    };

    const removeItem = (identity) => {
      let cart = getAll();
      let result = null;
      for (const key in cart) {
        if (parseInt(cart[key].identity) === parseInt(identity)) {
          delete cart[key];
        }
      }

      let ncart = [];
      for (const key in cart) {
        if (cart[key] !== null) {
          ncart.push(cart[key]);
        }
      }

      setCart(ncart);
      localStorage.setItem(VARS.name + "-cart", JSON.stringify(cart));
      return result;
    };

    const addToCart = (item) => {
      if(!item?.identity){
        return false;
      }
      if (exists(item)) {
        return false;
      }
      let cart = getAll();
      cart.push(item);
      setCart(cart);
      localStorage.setItem(VARS.name + "-cart", JSON.stringify(cart));
      //localStorage.setItem('tocartitems', JSON.stringify(cart));
      return true;
    };

    const clear = () => {
      setCart([]);
      localStorage.setItem(VARS.name + "-cart", JSON.stringify(cart));
    };

    return {
      all: getAll,
      exists: exists,
      get: getItem,
      remove: removeItem,
      add: addToCart,
      clear: clear,
    };
  };

  //Autenticacion
  const taxonomy_data = (full) => {
    let rv = user_field_value;
    let rf = user_field;
    return full ? rf : rv;
  };

  const auth = () => {
    return {
      reload: async () => {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await $.post('accounts/get', {jwt: accessToken});
          const user = (response.data?.idaccount?response.data: {});

          dispatch({
            type: 'RELOAD',
            payload: {
              user,
            },
          });
        }
      },
      getID: () => {
        if (!(state?.user?.idaccount === undefined)) {
          return state?.user?.idaccount + "" + state?.user?.idbusiness + "" + state?.user?.idrole;
        }
        return false;
      },
      displayName: () => {
        const d = taxonomy_data();
        return d?.name+" "+(d?.surname??'');
      },
      displayEmail: () => {
        const d = taxonomy_data();
        return d?.email;
      },
      name: () => {
        const d = taxonomy_data();
        return d?.name;
      },
      fullRegister: () => {
        const d = taxonomy_data();
        const surname_ready = d?.surname;
        const phone_ready = d?.phone;
        return surname_ready && phone_ready;
      },
      photo: () => {
        const d = taxonomy_data();
        return (d?.photo ? (VARS.host + VARS.uploads + d?.photo) : "default");
      },
      role: () => {
        return state?.user?.rol;
      },
      get: () => {
        return state?.user;
      },
      verified: (f) => {
        const u = state?.user;
        if(!f){
          return (u?.veryphone === 1 && u?.veryemail === 1);
        }else{
          if(f==='e'){
            return u?.veryemail === 1;
          }else{
            return u?.veryphone === 1
          }
        }
      },
      getData: taxonomy_data,
      is: () => {
        return isValidToken(localStorage.getItem("accessToken"));
      },
      save: (u) => {
        if (u?.idaccount && u?.username && (u?.password || u?.auth)) {
          const logkey = {
            logkey: u?.logkey
          };
          setUser(logkey);
          localStorage.setItem(VARS.name + "-session", JSON.stringify(logkey));
          return true;
        }
        return false;
      },
      destroy: () => {
        localStorage.clear();
        //setUser({});
        //setListNavbar([]);
        //localStorage.setItem(VARS.name + "-session", JSON.stringify(user));
        //localStorage.setItem(VARS.name + "-listnavbar", JSON.stringify(listnavbar));

        navigate("/login");
      },
      updated: async (values, alerts) => {
        if(state?.user?.idaccount && state?.user?.idbusiness){
          const fields = state?.user?.rol?.fields??[]
          const formData = new FormData();

          formData.append("idbusiness", state?.user?.idbusiness);
          
          for (const e in values) {
            const value = values[e];
            const field = fields.find((f)=>(f?.name === value?.name && f.typed === "profile"));
            if(field?.idfield){
              if(value?.value?.path){
                formData.append("file_"+value?.name, "file_0");
                formData.append("file_0",value?.value);
              }else{
                formData.append(value?.name, value?.value);
              }
              formData.append("field-"+value?.name, field?.idfield);
              formData.append("privacy"+value?.name, value?.privacy);              
            }
          }

          const response = await $.upload("accounts/save", formData);
          const user = response?.data??{}

          if(user?.message===undefined){
            setTaxonomys(user);
            //setSession(user?.jwt);
            if(auth().save(user)){
              await loadNavbar();
            }
            
            dispatch({
              type: 'LOGIN',
              payload: {
                user,
              },
            });
            alerts.success("¡El perfil se ha actualizado con éxito!")
          }else{
            alerts.err("¡Ha ocurrido un error al actualizar el perfil!")
          }

          return response;
        }
      }
    };
  };

  const getFieldBoat = () => {
    return boatInput;
  };

  const getFieldService = () => {
    return serviceInput;
  };

  //Lenguaje
  const switchLanguage = (lang) => {
    setLang(lang);
    Cookies.set('langUlaboat', lang, { expires: 30 });
    $.post("accounts/lang", {langcode: lang});
  };

  //Memoria de formularios
  const autosave = () => {
    return {
      form: (form) => {
        if (Object.hasOwnProperty.call(forms, form?.name)) {
          return forms[form?.name];
        }
        return {};
      },
      create: (form) => {
        if (!Object.hasOwnProperty.call(forms, form?.name)) {
          let f = forms;
          f[form?.name] = form;
          setForms(f);
          localStorage.setItem(VARS.name + "-forms", JSON.stringify(forms));
          return true;
        }
        return false;
      },
      save: (form) => {
        if (Object.hasOwnProperty.call(forms, form?.name)) {
          let f = forms;
          for (const e in form) {
            if (
              Object.hasOwnProperty.call(f[form?.name], e) &&
              e !== "name" &&
              e !== "data"
            ) {
              f[form?.name][e] = form[e];
              if (e === "fields") {
                if (f[form?.name][e]["password"]) {
                  f[form?.name][e]["password"] = "";
                }
              }
            }
          }
          setForms(f);
          localStorage.setItem(VARS.name + "-forms", JSON.stringify(forms));
          return true;
        }
        return false;
      },
      destroy: (form) => {
        if (Object.hasOwnProperty.call(forms, form?.name)) {
          let f = {};
          for (const e in forms) {
            if (e !== form?.name) {
              f[e] = forms[e];
            }
          }
          setForms(f);
          localStorage.setItem(VARS.name + "-forms", JSON.stringify(forms));
          return true;
        }
        return false;
      },
    };
  };

  //Mensajes en memoria
  const memory = () => {
    return {
      messages: (message) => {
        if (Object.hasOwnProperty.call(messages, message?.page)) {
          return messages[message?.page];
        }
        return {};
      },
      save: (message) => {
        let f = messages??{};
        f[message?.page] = message;
        setMessages(f);
        localStorage.setItem(VARS.name + "-messages", JSON.stringify(messages));
        return true;
      },
      destroy: (message) => {
        if (Object.hasOwnProperty.call(messages, message?.page)) {
          let f = {};
          for (const e in messages) {
            if (e !== message?.page) {
              f[e] = messages[e];
            }
          }
          setMessages(f);
          localStorage.setItem(
            VARS.name + "-messages",
            JSON.stringify(messages)
          );
          return true;
        }
        return false;
      },
    };
  };

  const getCurrency = () => {
    return VARS.divisas.find((divisa) => divisa.ID === (currency ?? "euro"));
  };

  const setTaxonomys = (user) => {
    let rv = user_field_value;
    let rf = user_field;
    if (
      user?.taxonomys &&
      (Object.keys(rv).length === 0 || Object.keys(rf).length === 0)
    ) {
      for (const e in user?.taxonomys) {
        const taxonomy = user?.taxonomys[e];
        let value = taxonomy?.value;
        if (taxonomy?.field === "photo" && value === "") {
          value = "user-empty.webp";
          taxonomy.value = value;
        }
        rv[taxonomy?.field] = value;
        rf[taxonomy?.field] = taxonomy;
      }
      setUserValue(rv);
      setUserField(rf);
    }
  }

  const loadNavbar = async () => {
    const NavList = ((await $.post("menus/list"))?.data || []);
    setListNavbar(NavList);
  }

  const login = async (user) => {
    setTaxonomys(user);
    //console.log(((user?.langcode??'es_ES').split('_')[0]??'es'))
    switchLanguage(((user?.langcode??'es_ES').split('_')[0]??'es'));
    setSession(user?.jwt);
    if(auth().save(user)){
      await loadNavbar();
    }
    
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (user) => {
    setTaxonomys(user);
    setSession(user?.jwt);
    //await loadNavbar();
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    setUserValue({});
    setUserField({});
    dispatch({ type: 'LOGOUT' });
    auth().destroy();
  };

  const paths = (u) => {
    const role = u?.rol;
    const permissions = PATH[role?.code]??{};
    return {...PATH.public, ...permissions}
  }

  const censure = async () => {
    const response = await $.get("options", {name: "censure"});
    const data = (response.data??[]);
    const censure = ((data[0]??{value: ""})?.value).split(",");
    return censure;
  }

  // Definimos los valores que queremos pasar en el contexto
  const contextValues = {
    ...state,
    JSON_PARSE,
    theme,
    currency,
    setCurrency,
    getCurrency,
    lang,
    switchLanguage,
    auth,
    navbar,
    setNavbar,
    listnavbar,
    cancellations,
    typedboat,
    getField,
    getFieldBoat,
    getFieldService,
    navigate,
    autosave,
    memory,
    alert,
    setAlert,
    Cart,
    login,
    register,
    logout,
    paths,
    censure,
    isDesktop,
    isSmallMobile
  };

  useEffect(() => {
    if(!versioned?.ver || !loading){
      $.post("options/version", {
        idbusiness: VARS?.idbusiness,
      }).then((response)=>{
        const verserver = response?.data?.ver??'';
        const verplataform = VARS?.version;
        const current_versioned = verserver+'.'+verplataform;
        if(versioned?.ver !== current_versioned){
          localStorage.clear();
          setVersioned({ver: current_versioned});
        }
        //setCancellations(response?.data??[]);
      }).catch(function (error) {
        //setCancellations([]);
      });      
    }


    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await $.post('accounts/get', {jwt: accessToken});
          const user = (response.data?.idaccount?response.data: {});
          // eslint-disable-next-line
          setTaxonomys(user);
          await loadNavbar();

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: {},
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: {},
          },
        });
      }
    };

    if(!cancellations){
      setCancellations(Cache?.global?.cancellations??[]);
      /*$.get("terms", {
        idbusiness: VARS?.idbusiness,
        idtaxonomy: 33,
        status: "publish",
        enabled: 1,
      }).then((response)=>{
        setCancellations(response?.data??[]);
      }).catch(function (error) {
        setCancellations([]);
      });*/
    }

    if (!typedboat) {
      setTypedboat(Cache?.global?.typedboats??[]);
      /*$.get("terms", {
        idbusiness: VARS?.idbusiness,
        idtaxonomy: 6,
        status: "publish",
        enabled: 1,
      }).then(function (response) {
        setTypedboat(response?.data??[]);
      }).catch(function (error) {
        setTypedboat([]);
      });*/
    }
    if (!loading) {
      setLoading(true);
      setBoatInput(Cache?.global?.entity_boats??[]);
      setServiceInput(Cache?.global?.entity_service??[]);
      /*if((boatInput?.length??0) === 0 ){
        $.post("taxonomyfields/inputfields", {
          idbusiness: VARS.idbusiness,
          idtaxonomy: 7,
          enabled: 1,
        }).then((response) => {
          const data = response?.data || [];
          setBoatInput(data);
        });        
      }*/

      /*if((serviceInput?.length??0) === 0){
        $.post("taxonomyfields/inputfields", {
          idbusiness: VARS.idbusiness,
          idtaxonomy: 8,
          enabled: 1,
        }).then((response) => {
          const data = response?.data || [];
          setServiceInput(data);
        });        
      }*/

      if(!state?.isInitialized){
        initialize();
      }
    }

    /*if (auth().is() && listnavbar.length === 0) {
      
    }*/
    localStorage.setItem(VARS.name + "-boatinput", JSON.stringify(boatInput));
    localStorage.setItem(
      VARS.name + "-serviceinput",
      JSON.stringify(serviceInput)
    );
    localStorage.setItem(VARS.name + "-forms", JSON.stringify(forms));
    localStorage.setItem(VARS.name + "-messages", JSON.stringify(messages));
    localStorage.setItem(VARS.name + "-session", JSON.stringify(user));
    localStorage.setItem(VARS.name + "-navbar", JSON.stringify(navbar));
    localStorage.setItem(VARS.name + "-versioned", JSON.stringify(versioned));
    localStorage.setItem(VARS.name + "-listnavbar", JSON.stringify(listnavbar));
    localStorage.setItem(VARS.name + "-cart", JSON.stringify(cart));
    // eslint-disable-next-line
  }, [
    state,
    typedboat,
    cancellations,
    forms,
    boatInput,
    serviceInput,
    messages,
    user,
    navbar,
    versioned,
    loading,
    cart,
    listnavbar,
  ]);

  return (
    <NooboxContent.Provider value={contextValues}>
      {children}
    </NooboxContent.Provider>
  );
};
